import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Components/Dashboard/Header';
import DashboardMenu from './Components/Dashboard/DashboardMenu';
import { Provider } from 'react-redux';
import { store } from './lib/store';
import Products from './Components/Dashboard/ProviderPanel/Products';
import Projects from './Components/Dashboard/EmployerPanel/Projects';
import ContactorProjects from './Components/Dashboard/ContactorPanel/Projects';
import { useAuth } from './AuthContext';
import DashboardLayout from './Components/DashboardLayout';

function App() {
  const user = useAuth();

  return (
    <DashboardLayout>

      {
        user.user?.roleId == 1 ?
          <Projects />
          :
          user.user?.roleId == 2 ?
            <ContactorProjects />
            :
            user.user?.roleId == 3 ?
              <Products />
              :
              ""
      }
    </DashboardLayout>

  );
}

export default App;
