import React, { useEffect, useState } from 'react'
import DashboardLayout from '../Components/DashboardLayout'
import { Modal } from '@mui/material'
import Button from '../Components/Button'
import httpClient from '../API'
import { HTMLParser } from '../utils/helpers'
import toast from 'react-hot-toast'

const ContentManager = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState<any>({
        id: null,
        title: "",
        description: "",
        type: "",
        image: ""
    })

    const toggle = () => setOpen(prev => !prev)

    const [articles, setArticles] = useState<any[]>([])

    const toggleArticle = (id: number, type: "CONFIRM" | "NOT_CONFIRM") => {
        httpClient.post(`/api/articles/toggle/confirm/${id}`, {}).then(() => {
            toast.success(type === 'CONFIRM' ? "محتوا با موفقیت تایید شد." : "رد محتوا با موفقیت انجام شد.")
            getArticles();
            setModalData({
                id: null,
                title: "",
                description: "",
                type: "",
                image: ""
            });
            toggle()
        })
    }

    const getArticles = async () => {
        await httpClient("/api/articles").then(res => setArticles(res?.data?.data));
    }

    const addToBest = async (item: any) => {
        await httpClient.put(`/api/articles/${item?.id}`, { ...item, isBest: true }).then(() => {
            toast.success("محتوا با موفقیت به لیست بهترین ها پیوسته شد.")
        })
    }

    useEffect(() => {
        getArticles()
    }, [])

    return (
        <DashboardLayout >
            <div className='bg-white p-10 rtl text-right'>
                <h1 className='text-3xl mb-6 font-bold'>مدیریت محتوا</h1>
                {
                    articles?.map(item =>
                        <div key={item?.id} className='flex flex-row-reverse items-stretch gap-10 rounded-[16px] mb-5'>
                            <img className='w-1/4 rounded-tr-[8px] rounded-br-[16px]' src={item?.thumbnail === "" ? "/assets/building.png" : process.env.REACT_APP_URL + '/' + item?.thumbnail} />
                            <div className='flex-1 '>
                                <h2 className='text-2xl font-bold'>{item?.title} {!item?.isActive ? <span className='text-[red] text-[16px]'>{'(غیر فعال)'}</span> : null}</h2>
                                <div className='text-[#818181] mt-2'>{item?.type === "news" ? "خبر" : "مقاله"} {item?.type === "news" && item?.isBest ? <span className='text-[#01055B]'>{"(بهترین)"}</span> : ""}</div>
                                <div className='w-[490px] h-[22px] rtl overflow-hidden text-ellipsis whitespace-nowrap ml-auto text-right mt-4' style={{ direction: "rtl" }}>
                                    {HTMLParser(item?.content)}
                                </div>
                                <div className='w-fit mr-auto flex items-center gap-4'>
                                    {
                                        item?.type === "news" && !item?.isBest &&
                                        <Button variant="contained" className='block mt-4 w-fit bg-white font-bold' style={{ color: "#01055B", border: "1px solid #01055B" }} onClick={() => addToBest(item)}>افزودن به بهترین</Button>
                                    }

                                    <Button variant="contained" className='block mt-4 w-fit' onClick={() => {
                                        setModalData(item);
                                        toggle()
                                    }}>مشاهده و تایید</Button>
                                </div>
                            </div>

                        </div>
                    )
                }
            </div>


            <Modal open={open} onClose={toggle}>
                <div className='w-[60%] max-h-[80vh] overflow-y-auto mt-[10vh] mx-auto items-center justify-center bg-white p-10 rounded-lg' dir='rtl'>
                    <div className='flex-1 '>
                        <h2 className='text-2xl font-bold text-center'>{modalData?.title}</h2>
                        <div className='text-[#818181] mt-2 text-center'>{modalData?.type === "news" ? "خبر" : "مقاله"}</div>
                        <img className='w-1/4 rounded-[16px] mx-auto mt-5' src={modalData?.thumbnail === "" ? "/assets/building.png" : process.env.REACT_APP_URL + '/' + modalData?.thumbnail} />
                        <div className='text-right mt-4'>
                            {HTMLParser(modalData?.content)}
                        </div>
                    </div>

                    <div className='flex pb-[7px] w-full justify-center mt-10 gap-5'>
                        <Button type='button' className='px-10 py-3 text-[18px]' variant='contained' onClick={() => toggleArticle(modalData?.id, 'CONFIRM')}>
                            تایید مقاله
                        </Button>

                        <Button type='button' variant="outlined" className='px-10 py-3 text-[18px]' onClick={() => toggleArticle(modalData?.id, 'NOT_CONFIRM')}>
                            رد مقاله
                        </Button>

                    </div>
                </div>

            </Modal>
        </DashboardLayout>
    )
}

export default ContentManager