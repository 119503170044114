import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { HTMLParser } from '../utils/helpers';
import httpClient from '../API';
import { Link } from 'react-router-dom';

export default function HomePage() {
    const user = useAuth();
    const navigate = useNavigate()

    const [articles, setArticles] = useState<any[]>([])

    const getArticles = () => {
        httpClient("/api/articles").then(res => setArticles(res?.data?.data?.filter((item: any) => item?.type === "news" && item?.isActive)))
    }

    useEffect(() => {
        getArticles()
    }, [])


    if (window.innerWidth < 1000)
        return (
            <div className='text-center text-2xl font-semibold'>
                لطفا از طریق کامپیوتر وارد شوید
            </div>
        )

    return (
        <div>
            <Header />
            <div className='py-14 flex items-center gap-[105px] flex-row-reverse px-[5%] bg-[#F4F4F4]'>
                <div className='w-[43.84%]'>
                    <div className='text-[#140092] font-bold text-[57px] text-right leading-[113.2px]'>
                        سامانه یکپارچه مدیریت
                    </div>
                    <div className='text-[#140092] font-bold text-[43px] text-right leading-[85.4px]'>
                        پروژه های دانشگاه آزاد اسلامی
                    </div>
                    <div className='text-[#000000A3] text-[17px] mt-3 text-right'>
                        سامانه یکپارچه مدیریت پروژه های دانشگاه آزاد اسلامی با هدف معرفی و مدیریت پروژه های مطرح و سامانه مدیریت تامین کنندگان و پیمانکاران مجموعه
                    </div>
                    {
                        !user.user?.roleId ?
                            <div className='flex items-center flex-row-reverse mt-6 gap-3'>
                                <button onClick={() => navigate('/auth/sign-up')} className='bg-[#140092] text-white text-[18px] font-bold w-[150px] h-[45px] rounded-[57px]'>
                                    ثبت نام
                                </button>
                                <button onClick={() => navigate('/auth/login')} className='w-[150px] h-[45px] rounded-[57px] border border-[#140092] text-[#140092] text-[18px] font-bold'>
                                    ورود
                                </button>
                            </div>
                            :
                            <div className='flex items-center flex-row-reverse mt-6 gap-3'>
                                <button onClick={() => navigate('/store')} className='bg-[#140092] text-white text-[18px] font-bold w-[150px] h-[45px] rounded-[57px]'>
                                    پنل کاربری
                                </button>
                                <button onClick={() => navigate('/dashboard')} className='w-[150px] h-[45px] rounded-[57px] border border-[#140092] text-[#140092] text-[18px] font-bold'>
                                    فروشگاه
                                </button>
                            </div>
                    }
                </div>
                <div className='flex-1 flex items-center gap-6'>
                    <img src='/assets/mini_buildings.svg' />

                    <div>
                        <img src='/assets/buildings.svg' className='ml-4 mb-7' />
                        <img src='/assets/mini_buildings.svg' />
                    </div>

                </div>
            </div>
            <div className='bg-white py-14 px-[5%]'>
                <div className='text-[#140092] text-[32px] text-right border-r-2 border-b-2 border-[#140092] w-fit ml-auto p-3 rounded-md'>
                    آخرین اخبار
                </div>
                <Swiper
                    slidesPerView={5}
                    loop
                    autoplay
                    // navigation
                    pagination
                    modules={[Navigation, Pagination]}
                    className='h-[100%] '
                    spaceBetween={20}
                >
                    {
                        articles?.map(item => (
                            <SwiperSlide key={item?.id}>
                                <Link to={`/articles/${item?.slug}?contentId=${item?.id}`} className='w-[100%] p-3 bg-white rtl text-right mt-4 mb-10 shadow-lg rounded-2xl'>
                                    <img src={item?.thumbnail === "" ? "/assets/building.png" : process.env.REACT_APP_URL + '/' + item?.thumbnail} className='rounded-[16px]  h-[222px] w-full' />
                                    <div className='text-[#140092] text-[18px] leading-9 mt-2'>{item?.title || '-'}</div>
                                    <div className='text-[#818181] mt-1'>{item?.type === "news" ? "خبر" : "مقاله"}</div>
                                </Link>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <div className='bg-white py-5 px-[5%]'>
                <div className='text-[#140092] text-[32px] text-right border-r-2 border-b-2 border-[#140092] w-fit ml-auto p-3 rounded-md'>
                    برترین اخبار
                </div>
                <Swiper
                    slidesPerView={5}
                    loop
                    autoplay
                    // navigation
                    pagination
                    modules={[Navigation, Pagination]}
                    className='h-[100%] '
                    spaceBetween={20}
                >
                    {
                        articles?.filter(item => item?.isBest)?.map(item => (
                            <SwiperSlide key={item?.id}>
                                <Link to={`/articles/${item?.slug}?contentId=${item?.id}`} className='w-[100%] p-3 bg-white rtl text-right mt-4 mb-10 shadow-lg rounded-2xl'>
                                    <img src={item?.thumbnail === "" ? "/assets/building.png" : process.env.REACT_APP_URL + '/' + item?.thumbnail} className='rounded-[16px]  h-[222px] w-full' />
                                    <div className='text-[#140092] text-[18px] leading-9 mt-2'>{item?.title || '-'}</div>
                                    <div className='text-[#818181] mt-1'>{item?.type === "news" ? "خبر" : "مقاله"}</div>
                                </Link>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}
