import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { Link } from 'react-router-dom'
import httpClient from '../API'

const Articles = () => {
    const [articles, setArticles] = useState<any[]>([])

    // const articles = [
    //     {
    //         id: 1,
    //         title: "مقاله شماره 1",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png",
    //     },
    //     {
    //         id: 2,
    //         title: "مقاله شماره 2",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png"
    //     },
    //     {
    //         id: 3,
    //         title: "مقاله شماره 3",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png"
    //     },
    //     {
    //         id: 4,
    //         title: "مقاله شماره 4",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png"
    //     },
    //     {
    //         id: 5,
    //         title: "مقاله شماره 5",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png"
    //     },
    //     {
    //         id: 6,
    //         title: "مقاله شماره 6",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png"
    //     },
    //     {
    //         id: 7,
    //         title: "مقاله شماره 7",
    //         slug: "slug",
    //         content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
    //         type: "article",
    //         image: "/assets/building.png"
    //     },
    //     {
    //         id: 8,


    const getArticles = () => {
        httpClient("/api/articles").then(res => setArticles(res?.data?.data?.filter((item: any) => item?.type === "article")))
    }

    useEffect(() => {
        getArticles()
    }, [])

    return (
        <div>
            <Header />
            <div className='flex items-center flex-wrap w-full gap-[1.8%] px-[5%]'>
                {articles?.map((item, index) => <div
                    key={item?.id}
                    className={`flex flex-col justify-end items-end pb-6 px-[3.5%] bg-cover bg-center mb-4 h-[466px] rounded-lg ${index % 5 === 0 ? "w-[59.3%]" : (index + 3) % 3 === 0 ? "w-[59.3%]" : "w-[38.84%]"}`}
                    style={{ backgroundImage: item?.thumbnail === "" ? "/assets/building.png" : `url(${process.env.REACT_APP_URL + '/' + item?.thumbnail || ''})` }}
                >
                    <div className='bg-[#FFFFFF79] py-6 px-[4.2%] w-full text-right'>
                        <div className='text-[#01055B] text-[16px] font-bold'>
                            {item.title || "-"}
                        </div>
                        <div className='flex flex-row-reverse gap-3 items-center mt-1'>
                            <div className='text-[#01055B]'>{item?.type === "news" ? "خبر" : "مقاله"} </div>
                            <Link to={`/articles/${item?.slug}?contentId=${item?.id}`} className='underline text-[#01055B]'>مشاهده مطلب</Link>
                        </div>
                    </div>
                </div>
                )}

            </div>
        </div>
    )
}

export default Articles