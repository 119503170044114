import React, { ReactNode, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../lib/store';
import Header from '../Dashboard/Header';
import DashboardMenu from '../Dashboard/DashboardMenu';


const DashboardLayout = ({ children, hasMenu = true }: { children: ReactNode, hasMenu?: boolean }) => {

    return (
        <Provider store={store}>
            <div className='flex w-full'>
                <div className='w-full mr-[30px]'>
                    <Header />
                    {children}
                </div>
                {
                    hasMenu && <DashboardMenu />
                }
            </div>
        </Provider>
    )
}

export default DashboardLayout