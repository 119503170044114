import React, { useEffect, useRef, useState } from 'react'
import DashboardLayout from '../Components/DashboardLayout'
import { useForm } from 'react-hook-form'
import { ICmsFields } from '../types/cms'
import Input from '../Components/Input'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HTMLParser } from '../utils/helpers'
import httpClient from '../API'



const CmsPage = () => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm()

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState("")
    const inputFileRef = useRef(null);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [text, setText] = useState('<p>....</p>')

    const handleReset = () => { reset(); setText('<p>....</p>'); setSelectedFile(""); setSelectedImage(null) }

    const submitArticle = async (data: any) => {
        const formData = new FormData()

        formData.append("title", data?.title)
        formData.append("content", text)
        formData.append("type", data?.type)
        formData.append("slug", data?.slug)
        formData.append("categoryId", "1")
        formData.append("image", selectedFile)

        await httpClient.post("/api/articles", formData).then(res => {
            console.log(res?.data)
            handleReset()
        })
    }



    const handleImageChange = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0])
            // @ts-ignore
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }
    };

    useEffect(() => {
        setEditorLoaded(true);
    }, []);

    const editorConfiguration = {
        toolbar: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'findAndReplace',
            'fontFamily',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'textColorPicker',
            'backgroundColorPicker',
            'removeFormat',
            'table',
            'link',
            'insertTable',
            'mergeTableCells',
            'horizontalLine',
            'clearFormatting',
            'wordCount'
        ],
    };

    return (
        <DashboardLayout >
            <form onSubmit={handleSubmit(submitArticle)} className='w-[92%] mx-auto mt-5 shadow-md rounded-md '>
                {/* header */}
                <div className='flex justify-end items-center flex-row-reverse p-5 gap-5 border-b-[1px] mb-5'>
                    <button className='text-[blue] py-2 px-3 rounded-md' onClick={handleReset} type='button'>حذف همه</button>
                    <button className='border-[1px] border-[blue] text-[blue] py-2 px-3 rounded-md min-w-32' type='submit'>ذخیره</button>
                </div>
                <div className='p-5 flex flex-row-reverse flex-wrap rtl items-stretch gap-[4%]'>
                    <div className='w-[100%] lg:w-[48%]'>
                        <Input rest={{ ...register("title", {}) }} label='عنوان' fullWidth className='mb-4' />
                        {/* <Input rest={{ ...register("shortDescription", {}) }} label='توضیح کوتاه' fullWidth className='mb-4' /> */}
                        <Input rest={{ ...register("slug", {}) }} label='اسلاگ' fullWidth className='mb-4' />


                    </div>
                    <div className='w-[100%] lg:w-[48%] text-right'>
                        <div className='text-right'>
                            <label className='font-medium text-[16px] text-[#1C1C1C] '>
                                نوع محتوا
                            </label>
                            <select {...register("type")} className='mb-4 w-full rounded-[16px] px-[18px] py-[14px] focus:outline-none border-[#00000033] border placeholder:text-[#00000033] bg-transparent text-right'>
                                <option value="news">خبر</option>
                                <option value="article">مقاله</option>
                            </select>
                        </div>



                        <div>
                            <input type="file" accept="image/*" onChange={handleImageChange} hidden ref={inputFileRef} />
                            {/* @ts-ignore */}
                            <button type='button' onClick={() => inputFileRef.current.click()} className='mb-4 w-full rounded-[16px] px-[18px] py-[14px] bg-[blue] text-center text-white'>آپلود عکس</button>
                            {selectedImage ? <img src={selectedImage} alt="Selected" className='w-[250px] h-[250px] rounded-md mx-auto' /> : null}
                        </div>
                    </div>
                    {
                        editorLoaded ?
                            <div className='w-[100%]  text-right mt-5'>
                                <label className='font-medium text-[16px] text-[#1C1C1C] block mt-5'>
                                    متن
                                </label>

                                <CKEditor
                                    data={text || '<p></p>'}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setText(data);
                                    }}
                                    config={editorConfiguration}
                                    editor={ClassicEditor}
                                />
                                <label className='font-medium text-[16px] text-[#1C1C1C] block mt-5'>
                                    خروجی متن
                                </label>
                                <div className='mt-3 w-full rounded-[16px] px-[18px] py-[14px] focus:outline-none border-[#00000033] border placeholder:text-[#00000033] bg-transparent text-right'>
                                    {HTMLParser(text)}
                                </div>

                            </div> : null
                    }


                </div>
            </form>
        </DashboardLayout >
    )
}

export default CmsPage