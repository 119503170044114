import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { Provider } from 'react-redux'
import { store } from '../lib/store';
import Button from '../Components/Button';
import { title } from 'process';
import { getSuppliers } from '../API/services';

function SupplierCard({ title, description }: { title: string, description: string }) {

    return (
        <div className='bg-[#FAF9F9] w-full mb-[32px] rounded-[16px] flex flex-row-reverse'>
            <img src='/assets/building.png' className='rounded-r-[16px] w-[194px] h-[211px] object-cover' />
            <div className='text-right text-[#01055B] mt-[24px] px-[24px] flex-1'>
                <h2 className='text-[21px] font-medium'>
                    {title}
                </h2>
                <p className='mt-[16px] text-[14px]'>
                    {description}
                </p>
                <div className='w-full'>
                    <Button variant='contained' className='mr-auto block mt-5'>
                        نمایش مشخصات
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default function Suppliers() {
    const [suppliers, setSuppliers] = useState([])
    const suppliersList = [
        {
            "id": 1,
            "name": "مصلاح فروشی برادران اسدی",
            "description": "مصلاح فروشی برادران اسدی یکی از معتبرترین مراکز عرضه مصالح ساختمانی در منطقه است که با ارائه انواع مصالح با کیفیت، به نیازهای پروژه‌های ساختمانی پاسخ می‌دهد."
        },
        {
            "id": 2,
            "name": "مرکز تامین مصالح ساختمانی ایران",
            "description": "مرکز تامین مصالح ساختمانی ایران با داشتن انبارهای بزرگ و تنوع وسیع محصولات، امکان تهیه مصالح مورد نیاز پروژه‌های ساختمانی را فراهم می‌کند."
        },
        {
            "id": 3,
            "name": "فروشگاه مصالح ساختمانی پارسیان",
            "description": "فروشگاه مصالح ساختمانی پارسیان با ارائه خدمات پس از فروش و پشتیبانی 24 ساعته، به عنوان یکی از مراکز معتبر تامین مصالح در کشور شناخته شده است."
        },
        {
            "id": 4,
            "name": "گروه صنعتی مصالح ساختمانی زاگرس",
            "description": "گروه صنعتی مصالح ساختمانی زاگرس با تولید انواع مصالح سنگی و بتنی، به توسعه صنعت ساخت و ساز کشور کمک می‌کند و با ارائه خدمات پس از فروش، رضایت مشتریان را تضمین می‌کند."
        },
        {
            "id": 5,
            "name": "شرکت تامین مصالح ساختمانی خورشید",
            "description": "شرکت تامین مصالح ساختمانی خورشید با تمرکز بر کیفیت و قیمت رقابتی، به عنوان یکی از مراکز معتبری برای تامین مصالح ساختمانی در بازار شناخته شده است."
        }
    ]

    useEffect(() => {
        getSuppliers().then(res => setSuppliers(res?.data?.data))
    }, [])
    console.log(suppliers)
    return (
        <Provider store={store}>
            <Header />
            <div className='flex justify-center'>
                <div className="w-[80%] mx-auto py-5">
                    {
                        suppliersList?.map(item =>
                            <SupplierCard key={item?.id} title={item?.name} description={item?.description} />
                        )
                    }
                </div>
            </div>
        </Provider>
    )
}
