import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { Provider } from 'react-redux'
import { store } from '../lib/store';
import Button from '../Components/Button';
import { getContactors, getSuppliers } from '../API/services';

function ContactorCard({
    title, description
}: { title: string, description: string }) {

    return (
        <div className='bg-[#FAF9F9] w-full mb-[32px] rounded-[16px] flex flex-row-reverse'>
            <img src='/assets/building.png' className='rounded-r-[16px] w-[194px] h-[211px] object-cover' />
            <div className='text-right text-[#01055B] mt-[24px] px-[24px] flex-1'>
                <h2 className='text-[21px] font-medium'>
                    {title}
                </h2>
                <p className='mt-[16px] text-[14px] w-full'>
                    {
                        description
                    }
                </p>
                <div className='w-full'>
                    <Button variant='contained' className='mr-auto block mt-5'>
                        نمایش مشخصات
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default function Contactor() {
    const [contactorsList, setContactorsList] = useState([])
    const contactors = [
        {
            id: 1,
            "name": "شرکت پیمانکاری نوین سازه البرز",
            "description": "شرکت نوین سازه البرز یکی از برترین شرکت‌های پیمانکاری در زمینه ساخت و تعمیر ساختمان‌ها است که با استفاده از تکنولوژی‌های مدرن، خدمات با کیفیت بالا به مشتریان ارائه می‌دهد."
        },
        {
            id: 2,
            "name": "شرکت پیمانکاری مهرگان",
            "description": "شرکت مهرگان با بیش از 20 سال سابقه کاری، در زمینه اجرای پروژه‌های عمرانی و ساختمانی فعالیت می‌کند و با تیم متخصص خود، خدمات با کیفیت را ارائه می‌دهد."
        },
        {
            id: 3,
            "name": "شرکت پیمانکاری پارسان",
            "description": "شرکت پارسان با تمرکز بر روی کیفیت و استانداردهای بین‌المللی، در زمینه طراحی و اجرای پروژه‌های ساختمانی فعالیت می‌کند و با توجه به نیازهای مشتری، راه حل‌های مناسب ارائه می‌دهد."
        },
        {
            id: 4,
            "name": "شرکت پیمانکاری سینا سازه",
            "description": "شرکت سینا سازه با داشتن تیم مهندسی مجرب و تجربه گسترده در زمینه ساخت و تعمیرات ساختمان، خدمات متعددی در حوزه‌های مختلف ساختمانی ارائه می‌دهد."
        },
        {
            id: 5,
            "name": "شرکت پیمانکاری فرازان",
            "description": "شرکت فرازان با تمرکز بر روی استفاده از مواد اولیه با کیفیت و رعایت استانداردهای ملی و بین‌المللی، در زمینه اجرای پروژه‌های ساختمانی فعالیت می‌کند و با ارائه خدمات پس از فروش، رضایت مشتری را تضمین می‌کند."
        }
    ]

    useEffect(() => {
        getContactors().then(res => setContactorsList(res?.data?.data))
    }, [])

    return (

        <Provider store={store}>
            <Header />
            <div className='flex justify-center'>
                <div className="w-[80%] mx-auto py-5">
                    {
                        contactors?.map(item =>
                            <ContactorCard title={item?.name} description={item?.description} key={item?.id} />
                        )
                    }
                </div>
            </div>
        </Provider>
    )
}
