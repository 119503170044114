import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import httpClient from '../API'
import { useParams, useSearchParams } from 'react-router-dom'
import { HTMLParser } from '../utils/helpers'

const SingleArticle = () => {
    const data = {
        id: 2,
        title: "مقاله شماره 2",
        slug: "slug",
        content: "<p>این هم یک مقاله خوب و پر از محتوای با ارزش هست</p>",
        type: "article",
        image: "/assets/building.png"
    }

    const [articleData, setArticleData] = useState<any>()
    const [searchParams] = useSearchParams();
    const { id } = useParams();

    const getArticles = async () => {
        await httpClient("/api/articles").then(res => {
            console.log(res?.data?.data?.find((node: any) => node?.id === Number(searchParams.get('contentId'))))
            setArticleData(res?.data?.data?.find((node: any) => node?.id === Number(searchParams.get('contentId'))))
        })
    }

    console.log(articleData)

    useEffect(() => {
        getArticles()
    }, [])

    return (
        <div>
            <Header />
            <div className='w-full px-[5%] pt-8 pb-28' style={{ direction: "rtl" }}>
                {
                    articleData?.thumbnail &&
                    <img src={articleData?.thumbnail === "" ? "/assets/building.png" : process.env.REACT_APP_URL + '/' + articleData?.thumbnail} className='w-[75.34%] mx-auto rounded-md' />
                }
                {
                    articleData?.title ? <div className='text-[#01055B] mt-20 mb-4 text-[37px] font-bold'>{articleData?.title}</div> : <div>-</div>
                }
                {
                    articleData?.content ? <div>{HTMLParser(articleData?.content)}</div> : <div>-</div>
                }
            </div>
        </div>
    )
}

export default SingleArticle