import httpClient from ".."

export const getSuppliers = async () => {
    const response = await httpClient.get("/api/fetch/suppliers")
    return response
}

export const getContactors = async () => {
    const response = await httpClient.get("/api/fetch/employees")
    return response
}